import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import RouteHelper from '../helper/RouteHelper';
import routes from 'src/routes';
import userService from '../services/user.service';
import authService from '../services/auth.service';

export const PrivateRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const outlet = <Outlet />;
  const location = useLocation();
  const currentLocation = location.pathname;
  const RouteModuleID = RouteHelper.getRouteModuleID(currentLocation, routes);
  const permissionRequired = RouteHelper.getRouteModulePermissionCheck(currentLocation, routes);
  const { user: currentUser } = useSelector((state) => state.auth);
  const unrestrictedRoles = ['SuperAdmin', 'AppAdmin'];

  // Update document title
  useEffect(() => {
    document.title = 'GoAndroy - eLogger v5.3 | ' + RouteHelper.getRouteName(currentLocation, routes);
  }, [currentLocation]);

  useEffect(() => {
    const checkPermissions = async () => {
      // Redirect to login if no currentUser
 // If permission is not required, grant access
 if (!permissionRequired) {
  setHasPermission(true);
  setIsLoading(false);
  return;
}
      if (!currentUser) {
        setIsLoading(false);
        return <Navigate to="/login" replace />;
      }

      // Skip permission check if no module ID
      if (!RouteModuleID) {
        setIsLoading(false);
        return; 
      }

      // Grant access if user has an unrestricted role
      if (unrestrictedRoles.includes(currentUser.data.role)) {
        setHasPermission(true);
        setIsLoading(false);
        return;
      }

     

      try {
        // Fetch user permissions from your API
        const response = await authService.getUserPermission(currentUser.data.email, RouteModuleID);
        setHasPermission(response.data.permissions_status || false);
      } catch (error) {
        console.error('Error fetching permissions:', error);
        setHasPermission(false); // Set to false on error
      } finally {
        setIsLoading(false); // Ensure loading is set to false
      }
    };

    checkPermissions();
  }, [RouteModuleID, currentUser, location.pathname,permissionRequired]); // Only run effect when these change

  // Redirect based on permission check
  if (isLoading) {
    return <div className='loading'>Loading...</div>;
  }

  // Redirect to login if no current user
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  
  return hasPermission ? outlet : <Navigate to="/403" replace />; // Redirect to a "Forbidden" page if no permission
};
