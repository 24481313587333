import React from 'react'

const app_config = {
  // API_URL: 'https://core.cloudswitch.in/api/',
  //  API_URL: 'http://192.168.0.200:5000/api/',
  // API_URL: 'https://asia-south1-mr-foodie-pos.cloudfunctions.net/api/',
  API_URL: 'https://jumra-webhook.goandroy.com/api/',
}

export default app_config

