import axiosInstance from '../axios/AxiosInterceptor'
import app_config from 'src/constants.js'

// const API_URL = 'https://core.cloudswitch.in/api/'
const API_URL = app_config.API_URL


class UserService {
  
  AddUser = async (data) => {
    return axiosInstance.post(API_URL + 'auth/users/add_user', data)
  }

  saveData = async (data) => {
    return axiosInstance.post(API_URL + 'auth/users/edit/save', data)
  }
  getData = async (API, params) => {
   
    try {
      // Send GET request with params appended as query strings
      const response = await axiosInstance.get(API_URL + API, {
        params: params  // Automatically adds the params as query strings
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;  // Propagate the error to handle it outside if needed
    }
  };

  getUserList = async () => {
  
    try {
      // Send GET request with params appended as query strings
      const response = await axiosInstance.get(API_URL + 'auth/list/users/active');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;  // Propagate the error to handle it outside if needed
    }
    
  }

  
}

export default new UserService()
