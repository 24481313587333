import React from 'react'
const MOD_DASHBOARD = React.lazy(() => import('./modules/dashboard/Dashboard'))
const MOD_CORE_PROFILE = React.lazy(() => import('./modules/profile/Profile'))

const APP_WEBHOOK_LOGS = React.lazy(() => import('./modules/WebhookLogs/WebHookLogs'))
const APP_INVOICE_LOGS = React.lazy(() => import('./modules/InvoiceLogs/InvoiceLogs'))
const APP_INVOICE_VIEW = React.lazy(() => import('./modules/InvoiceLogs/InvoiceDetailsPage'))
const APP_BATCH_INVOICE_LOG = React.lazy(() => import('./modules/BatchInvoiceLogs/BatchInvoiceLogs'))
const APP_REPORTS_MASTER = React.lazy(() => import('./modules/BatchInvoiceLogs/Reports'))
const APP_BATCH_INVOICE_VIEW = React.lazy(() => import('./modules/BatchInvoiceLogs/BatchInvoiceDetailsPage'))

const APP_USERS = React.lazy(() => import('./modules/users/Users'));
const APP_USERS_ADD = React.lazy(() => import('./modules/users/UsersAddScreen'));
const APP_USERS_EDIT = React.lazy(() => import('./modules/users/UsersEditScreen'));
const MOD_401 = React.lazy(() => import('./modules/errors/Page401'))
const MOD_403 = React.lazy(() => import('./modules/errors/Page403'))


// Buttons

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    element: MOD_DASHBOARD,
    moduleID: 'MOD_DASHBOARD',
    permissionRequired:false
  },
  {
    path: '/401',
    name: 'Error 401',
    element: MOD_401,
    moduleID: 'MOD_401',
    permissionRequired:false
  },
  {
    path: '/403',
    name: 'Error 403',
    element: MOD_403,
    moduleID: 'MOD_403',
    permissionRequired:false
  },
  {
    path: '/profile',
    name: 'Profile',
    element: MOD_CORE_PROFILE,
    moduleID: 'MOD_CORE_PROFILE',
    permissionRequired:false
  },
  
  {
    path: '/webhook-logs',
    name: 'WebHook Logs',
    element: APP_WEBHOOK_LOGS,
    moduleID: 'ViewWebHookPage',
    permissionRequired:true
  },
  {
    path: '/webhook-logs/view',
    name: 'WebHook Logs',
    element: APP_WEBHOOK_LOGS,
    moduleID: 'ViewWebHookPageDetails',
    permissionRequired:true
  },
  {
    path: '/invoice-logs',
    name: 'Invoice Logs',
    element: APP_INVOICE_LOGS,
    moduleID: 'APP_INVOICE_MASTER',
    permissionRequired:true
  },
  {
    path: '/invoice-logs/view/:id',
    name: 'Invoice Logs',
    element: APP_INVOICE_VIEW,
    moduleID: 'APP_INVOICE_MASTER',
    permissionRequired:true
  },{
    path: '/van-sales',
    name: 'VAN Sales Invoices',
    element: APP_BATCH_INVOICE_LOG,
    moduleID: 'ViewVanSalesPage',
    permissionRequired:true
  },
  {
    path: '/van-sales/view/:id',
    name: 'VAN Sales Details',
    element: APP_BATCH_INVOICE_VIEW,
    moduleID: 'ViewVanSalesPage',
    permissionRequired:true
  },{
    path: '/users',
    name: 'Users',
    element: APP_USERS,
    moduleID: 'ViewUserPage',
    permissionRequired:true
  },{
    path: '/users/add',
    name: 'Add Users',
    element: APP_USERS_ADD,
    moduleID: 'ViewUserPage',
    permissionRequired:true
  },{
    path: '/users/view/:id',
    name: 'View Users',
    element: APP_USERS_EDIT,
    moduleID: 'APP_USERS_EDIT',
    permissionRequired:true
  },{
    path: '/reports',
    name: 'Reports',
    element: APP_REPORTS_MASTER,
    moduleID: 'ViewReportsPage',
    permissionRequired:true
  },
]
export default routes
